/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Tooltip, Space, Divider } from "antd";
import { AppstoreOutlined, DownOutlined } from "@ant-design/icons";
import DashboardToolbar from "../DashboardToolbar";
import Tag from "../Tag";

import styles from "./DashboardSelector.module.less";

const DashboardSelector = ({ dashboard, dashboards }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState("LAST_UPDATED_DESCENDING");
  const [searchString, setSearchString] = useState("");

  const navigate = useNavigate();

  const compareDashboards = (a, b) => {
    switch (sortDirection) {
      case "LAST_UPDATED_ASCENDING":
        return dayjs(a.updatedAt).diff(dayjs(b.updatedAt));

      case "LAST_UPDATED_DESCENDING":
        return dayjs(b.updatedAt).diff(dayjs(a.updatedAt));

      case "NAME_ASCENDING":
        return a.name.localeCompare(b.name);

      case "NAME_DESCENDING":
        return b.name.localeCompare(a.name);

      default:
        return 0;
    }
  };

  const dropdownItems = [
    ...dashboards
      .filter(
        (d) =>
          d.name.toLowerCase().includes(searchString.toLowerCase()) ||
          d.tags.some((tag) => tag.toLowerCase().includes(searchString.toLowerCase()))
      )
      .sort(compareDashboards)
      .map((d) => ({
        label: (
          <Link to={`/dashboards/${d.id}`}>
            <div className={styles.dropdownItemContainer}>
              {d.name}
              <div className={styles.dropdownTagsContainer}>
                {d.tags.map((tag) => (
                  <Tag key={tag} label={tag} />
                ))}
              </div>
            </div>
          </Link>
        ),
        key: d.id,
      })),
  ];

  return (
    <div className={styles.container}>
      <Tooltip title="All Dashboards">
        <div className={styles.iconContainer} onClick={() => navigate("/dashboards")}>
          <AppstoreOutlined />
        </div>
      </Tooltip>

      <Dropdown
        open={dropdownOpen}
        onOpenChange={(newState) => setDropdownOpen(newState)}
        overlayClassName={styles.dropdown}
        menu={{
          items: dropdownItems,
          selectedKeys: [dashboard?.id],
          onClick: ({ key }) => {
            if (key !== "toolbar") {
              setDropdownOpen(false);
            }
          },
        }}
        dropdownRender={(menu) => (
          <div>
            <div style={{ padding: 8, background: "white" }}>
              <DashboardToolbar
                searchString={searchString}
                setSearchString={setSearchString}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />
            </div>
            <Divider style={{ margin: 0 }} />
            {React.cloneElement(menu, { style: { maxHeight: 300, overflow: "scroll" } })}
          </div>
        )}
        trigger={["click"]}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
          className={styles.dropdownButton}
        >
          <Space className={styles.dropdownButtonContent}>
            {dashboard?.name}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default DashboardSelector;
